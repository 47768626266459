import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';

const MetaTags = ({
    keywords = [],
    noIndexNoFollow = false,
    title = "Ken's Foods | Salad Dressings &amp; Marinades | Official Site",
    description = "Ken&#39;s salad dressings were born in a Steakhouse and live on through bold tastes and fresh ingredients. Find offers, recipes, and over 60 flavors.",
    canonical = null
} = {}) => (
    <Helmet>
        <title itemprop="name">{title}</title>
        <meta property="og:title" name="twitter:title" content={title} />
        <meta property="og:type" content="website" />
        <meta name="description" content={description} />
        <meta itemprop="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        {noIndexNoFollow && <meta name="robots" content="noindex, nofollow" />}
        {!isEmpty(keywords) && <meta name="keywords" content={keywords} />}
        {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
)

export default memo(MetaTags);
