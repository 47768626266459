import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    protips: []
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_TIPS_SUCCESS:
            updatedState.protips = action.payload.protips;
            break;
        default:
            break;
    }

    return updatedState;
};
