import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const defaultSearchOptions = {
    term: '',
    ingredients: [],
    courses: [],
    lowTime: 15,
    highTime: 60,
    flavors: [],
    filterApplied: false,
    isFilterOpened: false
};

const initialState = {
    list: [],
    featured: [],
    detailed: { relatedProduct: {} },
    header: {},
    search: _clone(defaultSearchOptions),
    pages: {
        hasNext: false,
        current: 1,
        next: 2,
        prev: 1
    },
    totalCount: 0
};

export default (state = initialState, action) => {

    const newState = _clone(state);

    switch (action.type) {
        case types.FETCH_RECIPES_SUCCESS:

            let recipesForState;
            if (action.payload.advancePage) {
                // user clicked load more, add newly retrieved items to existing list
                const combinedList = state.list.concat(action.payload.recipes);
                recipesForState = [];
                combinedList.forEach((r) => {
                    if (recipesForState.findIndex((r2) => r.id === r2.id) === -1) {
                        recipesForState.push(r);
                    }
                });
            } else {
                // user changed filters, start a fresh list
                recipesForState = action.payload.recipes;
            }

            newState.list = recipesForState;
            newState.pages = action.payload.pages;
            newState.loaded = true;
            newState.totalCount = action.payload.items.total;
            break;

        case types.FEATURED_RECIPES_LOADED:
            newState.featured = action.payload;
            break;

        case types.FETCH_HEADER_RECIPE_SUCCESS:
            newState.header = action.payload;
            break;

        case types.FETCH_DETAILED_RECIPE_START:
            newState.detailed = initialState.detailed;
            break;

        case types.FETCH_DETAILED_RECIPE_SUCCESS:
            newState.detailed = action.payload.recipe;
            break;

        case types.UPDATE_RECIPE_FILTERS:
            newState.search = Object.assign({}, state.search, action.payload.filters);
            break;

        case types.RESET_RECIPE_FILTERS:
            newState.search = _clone(defaultSearchOptions);
            break;
        default:
            break;
    }

    return newState;
}
