import _clone from 'lodash/clone';

import {
    LOOKUP_DATA_FETCHED
} from '../actions/types';

const initialState = {
    flavors: [],
    diets: [],
    productlines: [],
    courses: []
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);
    
    switch (action.type) {
        case LOOKUP_DATA_FETCHED:
            action.payload.forEach((d) => {
                updatedState[d.dataset] = d.data.sort((a, b) => { return a.name.toLowerCase().localeCompare(b.name.toLowerCase())});
            });
            break;
        default:
            break;
    }

    return updatedState
}
