export const dietaryAttributes = {
    FAT_FREE: { id: 1, name: 'Fat Free' },
    GLUTEN_FREE: { id: 2, name: 'Gluten Free' },
    KOSHER: { id: 3, name: 'Kosher' },
    LITE: { id: 4, name: 'Lite' },
}

export const sizeAttributes = {
    NINE_OZ: { id: 1, name: '9 oz' },
    SIXTEEN_OZ: { id: 2, name: '16 oz' },
    TWENTY_FOUR_OZ: { id: 3, name: '24 oz' },
}

export const products = ["Ken's", "Simply Vinaigrettes", "Chef's Reserve"];

export const actionTypes = {
    FETCH_PRODUCTS_START: 'FETCH_PRODUCTS_START',
    FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_FAILED: 'FETCH_PRODUCTS_FAILED',

    FETCH_DETAILED_PRODUCT_START: 'FETCH_DETAILED_PRODUCT_START',
    FETCH_DETAILED_PRODUCT_SUCCESS: 'FETCH_DETAILED_PRODUCT_SUCCESS',
    FETCH_DETAILED_PRODUCT_FAILED: 'FETCH_DETAILED_PRODUCT_FAILED',

    FEATURED_PRODUCTS_LOADED: 'FEATURED_PRODUCTS_LOADED',

    UPDATE_FILTERS: 'UPDATE_FILTERS',
    RESET_FILTERS: 'RESET_FILTERS'
};
