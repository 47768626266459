import _clone from 'lodash/clone';
import {
    IS_MOBILE,
    LOAD_SEO_DATA_COMPLETE
} from '../actions/types';

const initialState = {
    isMobile: true,
    seo: {
        title: null,
        description: null,
        canonical: null
    }
};

export default (state = initialState, action) => {

    const newState = _clone(state);

    switch (action.type) {
        case IS_MOBILE:
            newState.isMobile = action.payload;
            break;
        case LOAD_SEO_DATA_COMPLETE:
            if (action.payload !== null) {
                const { title, description, canonical } = action.payload;
                newState.seo = { title, description, canonical };
            }
            break;
        default:
            break;
    }

    return newState;
}