import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const loadProtips = function* () {

    try {
        const response = yield call([ApiClient, ApiClient.get], '/api/protips');
        yield put({ type: types.FETCH_TIPS_SUCCESS, payload: response.data });
    }
    catch (error) {
        yield put({ type: types.FETCH_TIPS_FAILURE, error: { message: error.message } });
    }
};

export default [
    takeLatest(types.FETCH_TIPS_START, loadProtips)
];