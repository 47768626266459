import { takeLatest, put, call } from 'redux-saga/effects'
import qs from 'qs'

// import ApiClient from '../../helpers/api';
import { FETCH_LOOKUP_DATA, LOOKUP_DATA_FETCHED } from './actions/types'
import ApiClient from './helpers/api'

const loadLookupData = function* () {
    try {
        const dataset = [
            'flavors',
            'diets',
            'productlines',
            'courses',
            'ingredients',
        ]
        const query = qs.stringify({ dataset }, { arrayFormat: 'brackets' })
        const response = yield call(
            [ApiClient, ApiClient.get],
            `/api/lookup?${query}`
        )

        yield put({ type: LOOKUP_DATA_FETCHED, payload: response.data })
    } catch (error) {
        console.log('lookup data saga err: ', error)
        // yield put({ type: FETCH_PRODUCTS_FAILED, error: { message: error.message } });
    }
}

export default [takeLatest(FETCH_LOOKUP_DATA, loadLookupData)]
