import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { prepareGTMDataset } from '../../helpers/googleTagManager';

import './navigation.scss';

const menu = [
    {link: '/products', title: "Products", gtm: 'data-gtm-products-link-a'},
    {link: '/locator', title: "Product Locator", gtm: 'data-gtm-product-locator-link-a'},
    {link: '/recipes', title: "Recipes", gtm: 'data-gtm-recipes-link-a'},
    {link: '/collections', title: "Collections", gtm: 'data-gtm-collections-link-a'},
    {link: '/protips', title: "Pro-Tips", gtm: 'data-gtm-pro-tips-link-a'},
    {link: '/about', title: "About Ken's", gtm: 'data-gtm-about-ken-link-a'}
];

class Navigation extends Component {
    getNav() {
        let index = 0;

        return (
            menu.map((item) => {
                return (
                    <NavLink key={index++} {...prepareGTMDataset(item.gtm)} to={item.link} onClick={this.props.closeCallback}>{item.title}</NavLink>
                )
            })
        );
    };

    render() {
        return (
            <nav>
                {this.getNav()}
            </nav>
        );
    }
}

export default Navigation;
