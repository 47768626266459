import { takeLatest, put, call, all } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';
import { actionTypes as productActionTypes } from '../products/constants';
import { actionTypes as recipeActionTypes } from '../recipes/constants';

const fetchFeatured = function* () {


    try {
        const apiData = yield all([
            call([ApiClient, ApiClient.get], '/api/products/featured'),
            call([ApiClient, ApiClient.get], '/api/recipes/featured?intent=home')
        ]);
    
        for(let d in apiData) {
            d = apiData[d];

            if (d.data && d.data.hasOwnProperty('products')) {
                yield put({ type: productActionTypes.FEATURED_PRODUCTS_LOADED, payload: d.data.products });            
            }

            if (d.data && d.data.hasOwnProperty('recipes')) {
                yield put({ type: recipeActionTypes.FEATURED_RECIPES_LOADED, payload: d.data.recipes });            
            }
        }
    
        yield put({ type: types.FETCH_FEATURED_SUCCESS });
    }
    catch (err) {
        yield put({ type: types.FETCH_FEATURED_FAILED, error: err });
    }
};


export default [
    takeLatest(types.FETCH_FEATURED_START, fetchFeatured)
];
