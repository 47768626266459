import React, { Component, Suspense, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { screenResize } from './actions/screenResizeActions';
import { loadSeoData } from './actions/appActions';

import MetaTags from './components/meta';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AppRoutes from './app.routes';

import './app.scss';

const ModalError = lazy(() => import('./components/modal-error/ModalError'));
const ScrollTop = lazy(() => import('./components/scroll-top/ScrollTop'));
// const GoogleAnalytics = lazy(() => import('./googleAnalytics'));
const PinterestAnalytics = lazy(() => import('./pinterestAnalytics'));
// const Promo = lazy(() => import('./components/promo/Promo'));

class App extends Component {
    constructor(props) {
        super(props);
        this.handleScreenChange = this.handleScreenChange.bind(this);
        this.handleScreenChange();
    }

    componentDidMount() {
        this.props.loadSeoData(this.props.location.pathname);
        window.addEventListener('resize', this.handleScreenChange);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.loadSeoData(this.props.location.pathname);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleScreenChange);
    }

    handleScreenChange() {
        const { isMobile, screenResize } = this.props;
        const currentIsMobile = window.innerWidth < 768;
        if (isMobile !== currentIsMobile) {
            screenResize(currentIsMobile);
        }
    }

    getSeoProps() {
        const { seo } = this.props;
        if (seo.title === null) {
            return {};
        }

        const { title, description, canonical } = seo;
        return { title, description, canonical };
    }

    render() {
      const { location } = this.props;
      // const showPromo = !pathname.match('fresh-flavors');

      return (
          <div className="page">
              <MetaTags
                noIndexNoFollow={process.env.REACT_APP_ENVIRONMENT === 'staging'}
                {...this.getSeoProps()}
              />
              <Header location={this.props.location} />
              <main className="main">
                  <Suspense fallback={'Loading'}>
                      <AppRoutes location={location} />
                  </Suspense>
              </main>
              <Footer />
              <Suspense fallback={null}><ScrollTop /></Suspense>
              <Suspense fallback={null}><ModalError /></Suspense>
              <Suspense fallback={null}><PinterestAnalytics /></Suspense>
              {/*
              {showPromo &&
                  <Suspense fallback={null}><Promo /></Suspense>
              }
              */}
          </div>
      );
    }
};

const mapStateToProps = (state) => ({
    isMobile: state.main.isMobile,
    location: state.router.location,
    seo: state.main.seo
});

export default withRouter(connect(mapStateToProps, { screenResize, loadSeoData })(App));
