import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import mainReducer from './mainReducer';
import errorReducer from './errorReducer';
import lookupReducer from './lookupdataReducer';
import productsReducer from '../containers/products/reducer';
import recipesReducer from '../containers/recipes/reducer';
import protipsReducer from '../containers/protips/reducer';
import locatorReducer from '../containers/locator/reducer';
import aboutReducer from '../containers/about/reducer';
import contactUsReducer from '../containers/contact/reducer';

export default (history) =>  combineReducers({
    router: connectRouter(history),
    errors: errorReducer,
    main: mainReducer,
    products: productsReducer,
    recipes: recipesReducer,
    protips: protipsReducer,
    locator: locatorReducer,
    lookup: lookupReducer,
    about: aboutReducer,
    contact: contactUsReducer
});
