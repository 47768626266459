import 'react-app-polyfill/ie9';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import PageToTop from './components/PageToTop';
import configureStore from './store';
import history from './history';
import App from './App';

require('intersection-observer');

const initialState = {}
const store = configureStore(initialState, history);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <PageToTop>
                <App />
            </PageToTop>
        </ConnectedRouter>
    </Provider>
    ,
    document.getElementById('root')
);
