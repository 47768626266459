import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const loadProtips = function* () {

    try {
        const response = yield call([ApiClient, ApiClient.get], '/api/recipes/featured?intent=about');
        yield put({ type: types.FETCH_ABOUT_HEADER_RECIPE_SUCCESS, payload: response.data });
    }
    catch (error) {
        console.log('error retrieving header recipe details for about page: ', error);
    }
};

export default [
    takeLatest(types.FETCH_ABOUT_HEADER_RECIPE, loadProtips)
];