import { debounce, put, call, takeLatest } from "redux-saga/effects";
import qs from 'qs';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const loadRecipes = function* (action) {

    const query = qs.stringify(action.filters, {arrayFormat: 'brackets'});
    const url = `/api/recipes/?${query}`;

    try {
        const response = yield call([ApiClient, ApiClient.get], url);
        const payload = {
            pages: response.data.pages,
            recipes: response.data.recipes,
            items: response.data.items,
            advancePage: action.advancePage
        };
        yield put({ type: types.FETCH_RECIPES_SUCCESS, payload });
    }
    catch (error) {
        yield put({ type: types.FETCH_RECIPES_FAILED, error: { message: error.message } });
    }
};

const loadHeaderRecipe = function* (action) {

    const url = '/api/recipes/featured?intent=recipelist';
    try {
        const response = yield call([ApiClient, ApiClient.get], url);
        yield put({ type: types.FETCH_HEADER_RECIPE_SUCCESS, payload: response.data });
    }
    catch (error) {
        console.log('error retrieving recipe for list header details: ', error);
    }
}

const loadDetailedRecipe = function* (action) {
    const query = qs.stringify({slug: action.slug});
    const url = `/api/recipes/?${query}`;

    try {
        const response = yield call([ApiClient, ApiClient.get], url);
        const payload = {
            recipe: response.data.recipes.length ? response.data.recipes[0] : {}
        };
        yield put({ type: types.FETCH_DETAILED_RECIPE_SUCCESS, payload });
    }
    catch (error) {
        yield put({ type: types.FETCH_DETAILED_RECIPE_FAILED, error: { message: error.message } });
    }
}

export default [
    debounce(750, types.FETCH_RECIPES_START, loadRecipes),
    takeLatest(types.FETCH_HEADER_RECIPE_START, loadHeaderRecipe),
    takeLatest(types.FETCH_DETAILED_RECIPE_START, loadDetailedRecipe)
]
