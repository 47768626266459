export const filterIcons = [ 'beef', 'vegetables', 'pasta', 'poultry', 'seafood', 'lamb', 'pork', 'grain'];

export const actionTypes = {
    FETCH_RECIPES_START: 'FETCH_RECIPES_START',
    FETCH_RECIPES_SUCCESS: 'FETCH_RECIPES_SUCCESS',
    FETCH_RECIPES_FAILED: 'FETCH_RECIPES_FAILED',

    FETCH_HEADER_RECIPE_START: 'FETCH_HEADER_RECIPE_START',
    FETCH_HEADER_RECIPE_SUCCESS: 'FETCH_HEADER_RECIPE_SUCCESS',

    FEATURED_RECIPES_LOADED: 'FEATURED_RECIPES_LOADED',

    FETCH_DETAILED_RECIPE_START: 'FETCH_DETAILED_RECIPE_START',
    FETCH_DETAILED_RECIPE_SUCCESS: 'FETCH_DETAILED_RECIPE_SUCCESS',
    FETCH_DETAILED_RECIPE_FAILED: 'FETCH_DETAILED_RECIPE_FAILED',

    UPDATE_RECIPE_FILTERS: 'UPDATE_RECIPE_FILTERS',
    RESET_RECIPE_FILTERS: 'RESET_RECIPE_FILTERS'
};
