//errors
export const ADD_ERROR = "ADD_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//lookupdata
export const FETCH_LOOKUP_DATA='FETCH_LOOKUP_DATA';
export const LOOKUP_DATA_FETCHED='LOOKUP_DATA_FETCHED'

//screen resize
export const IS_MOBILE = 'IS_MOBILE';

// seo
export const LOAD_SEO_DATA = 'LOAD_SEO_DATA';
export const LOAD_SEO_DATA_COMPLETE = 'LOAD_SEO_DATA_COMPLETE';
