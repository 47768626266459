import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import ClassNames from 'classnames';

import Navigation from '../../components/navigation/Navigation';
import './header.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalActive: false,
            isMobileMenuActive: false,
            isMobile: this.setViewMode(),
            isScroll: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const isScroll = window.pageYOffset > 16;
        if (isScroll !== this.state.isScroll) {
            this.setState({ isScroll })
        }
    }

    componentWillMount() {
        if (!this.props.orderAddress) {
            this.toggle('isModalActive');
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    setViewMode() {

        window.addEventListener('resize', () => {

            this.setState({
                isMobile: window.innerWidth < 850
            });
        }, false);

        return window.innerWidth < 850;
    }

    toggle (prop) {
        this.setState({[prop]: !this.state[prop]});
    }

    closeMenu() {
      this.setState({ isMobileMenuActive: false })
    }

    render() {
        let desktopMenu = null;
        let mobileMenu = null;
        let headerClass = '';

        //elements
        let logo = <div data-gtm-logo-link-a className='logo' onClick={() => this.props.push('/')}/>

        if (!this.state.isMobile) {

            if (this.props.location.pathname.includes('weeknights')) {
                headerClass = 'no-menu ';
                desktopMenu = <div className="header-wrapper desktop">
                    {logo}
                </div>;
            } else if (this.props.location.pathname.includes('simple-meals') || this.props.location.pathname.includes('grill-flavor') || this.props.location.pathname.includes('/summer/great-flavor') || this.props.location.pathname.includes('/summer/creamy') || this.props.location.pathname.includes('favorite-flavor') || this.props.location.pathname.includes('/recipes/creamy-caesar') || this.props.location.pathname.includes('bold-flavors') || this.props.location.pathname.includes('/recipes/caesar') || this.props.location.pathname.includes('/recipes/ranch')) {
                headerClass = 'kens-sv ';
                desktopMenu = <div className="header-wrapper desktop">
                    {logo}
                </div>;
            } else if (this.props.location.pathname.includes('/summer-recipes/great-flavor')) {
                headerClass = 'great-flavor-lp ';
                desktopMenu = <div className="header-wrapper desktop">
                    {logo}
                    <Navigation />
                </div>;
            } else {
                desktopMenu = <div className="header-wrapper desktop">
                    {logo}
                    <Navigation />
                </div>;
            }
        }

        if (this.state.isMobile) {

            const menuClasses = {
                'mobile-nav-wrapper': true,
                'visible': this.state.isMobileMenuActive
            }

            if (this.props.location.pathname.includes('weeknights')) {
                headerClass = 'no-menu ';
                mobileMenu = <div className="header-wrapper mobile">
                    {logo}
                </div>;
            } else if (this.props.location.pathname.includes('simple-meals') || this.props.location.pathname.includes('grill-flavor') || this.props.location.pathname.includes('/summer/great-flavor') || this.props.location.pathname.includes('/summer/creamy') || this.props.location.pathname.includes('favorite-flavor') || this.props.location.pathname.includes('/recipes/creamy-caesar') || this.props.location.pathname.includes('bold-flavors') || this.props.location.pathname.includes('/recipes/caesar')) {
                headerClass = 'kens-sv ';
                mobileMenu = <div className="header-wrapper mobile">
                    {logo}
                </div>;
            } else if (this.props.location.pathname.includes('/summer-recipes/great-flavor')) {
                headerClass = 'kens-sv ';
                mobileMenu = <div className="header-wrapper mobile">
                    {logo}
                    <button
                        onClick={() => this.toggle('isMobileMenuActive')}
                        className="menu-btn">
                        <span /><span /><span />
                    </button>
                    <div className="clearfix"></div>
                    <div className={ClassNames(menuClasses)}>
                        <button
                            onClick={() => this.toggle('isMobileMenuActive')}
                            className="close-btn">
                            <span /><span />
                        </button>
                        <Navigation closeCallback={this.closeMenu.bind(this)} />
                    </div>
                </div>;
            } else {
                mobileMenu = <div className="header-wrapper mobile">
                    {logo}
                    <button
                        onClick={() => this.toggle('isMobileMenuActive')}
                        className="menu-btn">
                        <span /><span /><span />
                    </button>
                    <div className="clearfix"></div>
                    <div className={ClassNames(menuClasses)}>
                        <button
                            onClick={() => this.toggle('isMobileMenuActive')}
                            className="close-btn">
                            <span /><span />
                        </button>
                        <Navigation closeCallback={this.closeMenu.bind(this)} />
                    </div>
                </div>;
            }
        }

        return (
            <header className={`${this.state.isScroll ? (headerClass + 'wide') : headerClass} no-print`}>
                {desktopMenu}
                {mobileMenu}
            </header>
        );
    }
}


const mapStateToProps = state => ({
});

export default withRouter(connect(mapStateToProps, { push })(Header));
