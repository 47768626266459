import _clone from 'lodash/merge';

import { actionTypes as types } from './constants';

const initialState = {
    recipe: {}
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_ABOUT_HEADER_RECIPE_SUCCESS:
            updatedState.recipe = action.payload;
            break;
        default:
            break;
    }

    return updatedState;
};
