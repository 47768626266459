import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    locations: [],
    lat: null,
    lng: null,
    searchCompleted : false,
    isSearching: false
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_STORE_INVENTORY:
            updatedState.isSearching = true;
            updatedState.locations = [];
            break;

        case types.FETCH_STORE_INVENTORY_SUCCESS:
            const { list, lat, lng } = action.payload;

            updatedState.locations = list;
            updatedState.lat = lat;
            updatedState.lng = lng;
            updatedState.searchCompleted = true;
            updatedState.isSearching = false;
            break;
        case types.FETCH_STORE_INVENTORY_FAILED: 
            updatedState.isSearching = false;
            updatedState.searchCompleted = true;
            break;
        default:
            break;
    }

    return updatedState;
};
