import { takeLatest, put, call } from 'redux-saga/effects';
import qs from 'qs';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const url = '/api/products';

const loadProducts = function* () {
    try
    {
        const response = yield call([ApiClient, ApiClient.get], url);

        yield put({ type: types.FETCH_PRODUCTS_SUCCESS, payload: response.data });
    }
    catch (error) {
        yield put({ type: types.FETCH_PRODUCTS_FAILED, error: { message: error.message } });
    }
};

const fetchDetailedProduct = function* (action) {
    try
    {
        const response = yield call([ApiClient, ApiClient.get], `${url}/detailed/?${qs.stringify({slug: action.slug})}`);
        yield put({ type: types.FETCH_DETAILED_PRODUCT_SUCCESS, payload: response.data });
    }
    catch (error) {
        yield put({ type: types.FETCH_DETAILED_PRODUCT_FAILED, error: { message: error.message } });
    }
}

export default [
    takeLatest(types.FETCH_PRODUCTS_START, loadProducts),
    takeLatest(types.FETCH_DETAILED_PRODUCT_START, fetchDetailedProduct)
];
