import { takeLatest, put } from 'redux-saga/effects';
import qs from 'qs';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

//TODO: Move url/key to config
const gapiUrl = 'https://maps.googleapis.com/maps/api/geocode/json?';
const gapiKey = 'AIzaSyAFctPnw2rLauNwoDTlqaf22lSJZGMTczA';

const fetchStoreInventory = function* (action) {

    try {
        const qsObj = {
            address: action.zip,
            sensor: false,
            key: gapiKey
        }
    
        const gapiData = yield ApiClient.get(`${gapiUrl}${qs.stringify(qsObj)}`, {headers: {}});
        const geometry = gapiData.results[0].geometry;
        const lat = geometry.location.lat;
        const lng = geometry.location.lng;
    
        // Denver
        // const lat = 39.6696322;
        // const lng = -104.9330448;
    
        const locatorArgs = {
            upc: action.upc,
            zipcode: action.zip,
            latlng: `${lat},${lng}`,
            radius: action.radius
        };
        const locatorData = yield ApiClient.post('/api/locator', locatorArgs);
        
    
        yield put({ type: types.FETCH_STORE_INVENTORY_SUCCESS, payload: { list: locatorData.data, lat, lng } });
    } catch (err) {
        yield put({ type: types.FETCH_STORE_INVENTORY_FAILED, err });
    }
}

export default [
    takeLatest(types.FETCH_STORE_INVENTORY, fetchStoreInventory)
];