import { all, takeLatest, call, put } from 'redux-saga/effects';

import {
    LOAD_SEO_DATA,
    LOAD_SEO_DATA_COMPLETE
} from './actions/types';

import ApiClient from './helpers/api';

import productsSagas from './containers/products/saga';
import recipesSagas from './containers/recipes/saga';
import protipsSagas from './containers/protips/saga';
import locatorSagas from './containers/locator/saga';
import homeSagas from './containers/home/saga';
import lookupSagas from './lookupSaga';
import aboutSaga from './containers/about/saga';
import contactUsSaga from './containers/contact/saga';

export default function* () {
    yield all([
        takeLatest(LOAD_SEO_DATA, loadSeoData),
        ...productsSagas,
        ...recipesSagas,
        ...protipsSagas,
        ...locatorSagas,
        ...homeSagas,
        ...lookupSagas,
        ...aboutSaga,
        ...contactUsSaga
    ])
};

const loadSeoData = function* (action) {
    try {

        const apiData = yield call([ApiClient, ApiClient.get], `/api/seo?path=${action.payload.path}`);

        yield put({ type: LOAD_SEO_DATA_COMPLETE, payload: apiData.data });
    }
    catch (err) {
        console.log(err);
        // yield put({ type: ADD_ERROR, message: err.message });
    }
}
