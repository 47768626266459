import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from "react-router";

import redirects from './redirects.json';
import routes from './routes.json';

const WaitingComponent = (Component) => {
    return props => (
        <Suspense fallback={null}><Component {...props} /></Suspense>
    );
}

export default ({ location }) => {

    const searchQuery = new URLSearchParams(location.search);
    return (
        <Switch>
            {redirects.map(({ from, to }) => {
                return (
                    <Route key={from} exec path={from}>
                        <Redirect to={to} />
                    </Route>
                )
            })}
            {routes.map(({ path, component, props }, index) => {
                const RouteComponent = lazy(() => import(`${component}.jsx`));

                const properties = props || {};
                if (path) {
                    properties.path = path;
                }

                return (
                    <Route key={index} component={WaitingComponent(RouteComponent)} {...properties} />
                );
            })}
            <Route exec path="/api/deeplink">
                <Redirect to={`/${searchQuery.get('t')}/${searchQuery.get('s')}`} />
            </Route>
        </Switch>
    );
};
