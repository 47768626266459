import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    showSuccessModal: false
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.SUBMIT_FEEDBACK_START:
        case types.SUBMIT_FEEDBACK_COMPLETED:
            updatedState.showSuccessModal = false;
            break;
        case types.SUBMIT_FEEDBACK_SUCCESS:
            updatedState.showSuccessModal = true;
            break;
        default:
            break;
    }

    return updatedState;
}