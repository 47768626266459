import { takeLatest, put } from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

const submitFeedback = function* (action) {
    try {

        const apiResponse = yield ApiClient.post('/api/contactus', action.payload)

        yield put({ type: types.SUBMIT_FEEDBACK_SUCCESS, payload: apiResponse });
    }
    catch (err) {
        yield put({ type: types.SUBMIT_FEEDBACK_FAILED, error: err });
    }
};

export default [
    takeLatest(types.SUBMIT_FEEDBACK_START, submitFeedback)
];
