import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';


const defaultSearchOptions = {
    isFilterApplied: false,
    isFilterOpen: false,
    term: '',
    flavors: [],
    diets: [],
    productlines: [],
    productsizes: []
};

const initialState = {
    sauces: [],
    featured: [],
    detailed: {},
    search: _clone(defaultSearchOptions)
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_PRODUCTS_SUCCESS:
            updatedState.sauces = action.payload.products;
            updatedState.loaded = true;
            break;
        case types.FEATURED_PRODUCTS_LOADED:
            updatedState.featured = action.payload;
            break;
        case types.FETCH_DETAILED_PRODUCT_START:
            updatedState.detailed = {};
            break;
        case types.FETCH_DETAILED_PRODUCT_SUCCESS:
            updatedState.detailed = action.payload;
            break;
        case types.UPDATE_FILTERS:
            updatedState.search = Object.assign({}, updatedState.search, action.payload.filters);
            updatedState.search.isFilterApplied =
                !!updatedState.search.term ||
                !!updatedState.search.flavors.length ||
                !!updatedState.search.diets.length ||
                !!updatedState.search.productlines.length ||
                !!updatedState.search.productsizes.length;
            break;
        case types.RESET_FILTERS:
            updatedState.search = _clone(defaultSearchOptions);
            break;
        default:
            break;
    }

    return updatedState;
}
